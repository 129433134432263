<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header :breadcrumb="currentFolder == ''" />
      <file-breadcrumb
        v-if="currentFolder != ''"
        :breadcrumbString="currentFolder"
        type="Presentation"
        @routeFiles="breadcrumbRoute"
      />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="pb-6">
          <div class="mx-auto px-4 sm:px-6 md:px-8 flex flex-col">
            <upload-modal
              @done="getFiles"
              :preceptoryId="preceptoryId"
              :pageType="pageType"
              :currentFolder="currentFolder"
              :user="user"
            />
            <folder-upload-modal
              @dismissFolder="getFiles"
              :preceptoryId="preceptoryId"
              :pageType="pageType"
              :currentFolder="currentFolder"
              :user="user"
            />
            <link-upload-modal
              @done="getFiles"
              :preceptoryId="preceptoryId"
              :pageType="pageType"
              :currentFolder="currentFolder"
              :user="user"
            />
            <item-viewer :item="viewedItem" :key="docViewerKey"></item-viewer>
            <CoolLightBox :items="media" :index="index" @close="index = null" />
            <iframe id="hiddenIframe" class="hidden"></iframe>
            <!-- LIST VIEW -->
            <data-table
              v-show="isListView"
              @deletePressed="removePressed($event)"
              @viewItemPressed="viewItem($event)"
              @viewToggle="viewToggle()"
              @filter="onFilter($event)"
              @updateDetail="updateDetail($event)"
              @changeIndex="changeIndex($event)"
              :filteredItems="filteredItems"
              :isListView="isListView"
              :getPermission="getPermission"
              :pageType="pageType"
              :updatePage="updatePage"
              :preceptoryId="preceptoryId"
            />
            <!-- GRID VIEW -->
            <grid-view
              v-show="!isListView"
              @deletePressed="removePressed($event)"
              @viewItemPressed="viewItem($event)"
              @viewToggle="viewToggle()"
              @filter="onFilter($event)"
              @sort="onSort($event)"
              @updateDetail="updateDetail($event)"
              @changeIndex="changeIndex($event)"
              :filteredItems="filteredItems"
              :isListView="isListView"
              :getPermission="getPermission"
              :updatePage="updatePage"
              :preceptoryId="preceptoryId"
              :pageType="pageType"
              :sortBy="sortBy"
            />
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import {
  HomeFooter,
  ItemViewer,
  UploadModal,
  FolderUploadModal,
  LinkUploadModal,
  FileBreadcrumb,
  DataTable,
  GridView,
} from "../../components";
import {
  isFolder,
  isLink,
  isYoutubeLink,
  isImageFileCheck,
  isVideoFileCheck,
  isOfficeFileCheck,
} from "../../utils/fileTypeCheck";
import { supportedExtensions } from "../../constants";
import api from "@/api/apiServices";
import { Storage } from "aws-amplify";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Presentation",
  components: {
    HomeHeader,
    HomeFooter,
    UploadModal,
    FolderUploadModal,
    LinkUploadModal,
    ItemViewer,
    FileBreadcrumb,
    DataTable,
    GridView,
    CoolLightBox,
  },
  async mounted() {
    if (this.getPermission(this.pageType, "get") == 0) {
      this.$fire({
        title: "Access Error",
        text: "User does not have permissions to access the page",
        type: "error",
      });
      this.$router.push({ name: "Home" });
    } else {
      // Initialize route parameters
      this.$route.meta.breadcrumb = this.$route.meta(this.$route).breadcrumb;
      this.folderPath = this.$route.params.path;
      this.fileName = this.$route.params.name;
      this.$router.replace({ query: { temp: Date.now() } });
      this.$router.replace({ query: { temp: undefined } });
      if (this.folderPath != undefined && this.fileName != undefined) {
        await this.recentView(this.fileName);
      } else {
        await this.getFiles();
      }
      // Page will automatically refresh every 30 minutes. Prevents error due to presign url expiring
      this.timer = setInterval(this.getFiles, 1800000);
    }
  },
  data() {
    return {
      folderPath: undefined,
      fileName: undefined,
      pageType: "Presentations",
      preceptoryId: "1",
      items: [],
      media: [],
      user: this.$store.getters["security/currentUser"],
      permission: this.$store.getters["security/currentPermission"],
      currentFolder: "",
      isListView: true,
      filter: "",
      filteredItems: [],
      sortBy: "-",
      viewedItem: {},
      docViewerKey: "docviewerkey-1",
      index: null,
      timer: undefined,
      loader: null,
    };
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    viewToggle() {
      this.isListView = !this.isListView;
    },
    async updatePage(folderName) {
      this.currentFolder = this.currentFolder + folderName;
      await this.getFiles(this.currentFolder);
    },
    async removePressed(item) {
      this.$confirm("Are you sure want to remove this?")
        .then((res) => {
          if (res) {
            if (item.fileType == "folder") {
              this.deleteFolder(item, false);
            } else {
              this.deleteFile(item);
            }
          }
        })
        .catch(() => {});
    },
    async breadcrumbRoute(path) {
      this.currentFolder = path;
      this.getFiles(path);
    },
    getPermission(module, type) {
      for (let i = 0; i < this.permission.length; i++) {
        const element = this.permission[i];
        if (module == element.module) {
          return element[type];
        }
      }
    },
    filterMedia(items) {
      this.media = [];
      var counter = 0;
      items.forEach((item) => {
        if (
          this.isVideo(item) ||
          this.isImage(item) ||
          this.isYoutubeLink(item)
        ) {
          item.src = item.url;
          item.id = counter;
          item.title = item.fileName;
          this.media.push(item);
          counter += 1;
        }
      });
    },
    async getPresignUrl() {
      if (this.items.length > 0) {
        var objs = JSON.parse(JSON.stringify(this.items));
        const promises = objs.map((item) => {
          if (!isFolder(item) && !isYoutubeLink(item) && !isLink(item)) {
            Storage.get(
              item.folderName.replace("public/", "") + item.fileName,
              { expires: 3600 }
            ).then(async (presigned) => {
              if (this.isOffice(item)) {
                item.url = await api.getShortLink(presigned);
              } else {
                item.url = presigned;
                item.src = presigned;
              }
            });
          }
        });
        await promises;
        this.items = objs;
        this.filteredItems = this.items;
        this.onSort();
        this.filterMedia(this.filteredItems);
      }
    },
    async getFiles(folderName = this.currentFolder) {
      let params = {
        preceptoryId: this.preceptoryId,
        type: this.pageType,
        folderName: folderName,
      };
      try {
        this.showLoader();
        this.items = [];
        this.filteredItems = [];
        var res = await api.getFiles(params);
        if (res.data) {
          // Workaround as url no longer is publically accessible
          res.data.data.forEach((item) => {
            if (!isYoutubeLink(item) && !isLink(item)) {
              item.url = "";
            }
          });
          this.items = res.data.data;
          await this.getPresignUrl();
          this.hideLoader();
        }
      } catch (err) {
        this.hideLoader();
        this.$fire({
          title: "Failed to retrieve files",
          text: err.message,
          type: "error",
        });
      }
    },
    async deleteFile(file) {
      let params = {
        preceptoryId: this.preceptoryId,
        type: this.pageType,
        fileId: file.fileId,
        modifiedBy: this.user.attributes.name,
        folderName: file.folderName,
        fileName: file.fileName,
      };
      try {
        this.showLoader();
        api.deleteFile(params).then((res) => {
          if (res.data) {
            this.hideLoader();
            this.$fire({
              title: "File removed",
              text: res.message,
              type: "success",
              timer: 3000,
            }).then(() => {
              this.getFiles();
            });
          }
        });
      } catch (err) {
        this.hideLoader();
        this.$fire({
          title: "Failed to retrieve files",
          text: err.message,
          type: "error",
        });
      }
    },
    updateFile(params) {
      try {
        this.showLoader();
        api.updateFile(params).then((res) => {
          if (res.data) {
            this.hideLoader();
            this.$fire({
              title: "File updated.",
              text: res.message,
              type: "success",
              timer: 3000,
            }).then(() => {
              this.getFiles();
            });
          }
        });
      } catch (err) {
        this.hideLoader();
        this.$fire({
          title: "Failed to retrieve files",
          text: err.message,
          type: "error",
        });
      }
    },
    updateDetail(file) {
      if (file.fileType == "folder") {
        let params = {
          preceptoryId: this.preceptoryId,
          type: this.pageType,
          folderName: this.currentFolder,
          fileId: file.fileId,
          newFileName: file.fileName,
          oldFileName: file.oldFileName,
          modifiedBy: this.user.attributes.name,
          description: file.description,
        };
        this.updateFolder(params);
      } else if (file.fileType == "youtube") {
        let params = {
          preceptoryId: this.preceptoryId,
          type: this.pageType,
          folderName: this.currentFolder,
          fileId: file.fileId,
          newfileName: file.fileName,
          oldFileName: file.oldFileName,
          modifiedBy: this.user.attributes.name,
          description: file.description,
          url: file.url,
        };
        if (
          file.oldFileName != file.fileName ||
          file.oldDescription != file.description ||
          file.oldUrl != file.url
        ) {
          this.updateFile(params);
        }
      } else {
        let params = {
          preceptoryId: this.preceptoryId,
          type: this.pageType,
          folderName: this.currentFolder,
          fileId: file.fileId,
          newfileName: file.fileName,
          oldFileName: file.oldFileName,
          modifiedBy: this.user.attributes.name,
          description: file.description,
        };
        if (
          file.oldFileName != file.fileName ||
          file.oldDescription != file.description
        ) {
          this.updateFile(params);
        }
      }
    },
    updateFolder(params) {
      try {
        this.showLoader();
        api.updateFolder(params).then((res) => {
          if (res.data) {
            this.hideLoader();
            this.$fire({
              title: "Folder updated.",
              text: res.message,
              type: "success",
              timer: 3000,
            }).then(() => {
              this.getFiles();
            });
          }
        });
      } catch (err) {
        this.hideLoader();
        this.$fire({
          title: "Failed to retrieve files",
          text: err.message,
          type: "error",
        });
      }
    },
    async deleteFolder(folder, recursive) {
      let params = {
        preceptoryId: this.preceptoryId,
        type: this.pageType,
        folderName: this.currentFolder + folder.fileName,
        recursive: recursive,
        modifiedBy: this.user.attributes.name,
        fileId: folder.fileId,
      };
      try {
        this.showLoader();
        api.deleteFolder(params).then((res) => {
          if (res) {
            this.hideLoader();
            if (res.data.Message == "One or more files exist in the folder.") {
              this.$confirm(
                "One or more files exist in the folder, Are you sure want to remove this folder and it's content?"
              )
                .then((res) => {
                  if (res) {
                    this.deleteFolder(folder, true);
                    setTimeout(2000);
                  }
                })
                .catch(() => {});
            } else {
              this.$fire({
                title: "Folder succesfully deleted.",
                type: "success",
                timer: 3000,
              }).then(() => {
                this.getFiles();
              });
            }
          }
        });
      } catch (err) {
        this.hideLoader();
        this.$fire({
          title: "Failed to delete.",
          text: err.message,
          type: "error",
        });
      }
    },
    async recentView(fileName) {
      await this.updatePage(this.folderPath);
      setTimeout(() => {
        var viewedItem = this.filteredItems
          .filter((item) => item.fileName == fileName)
          .pop();
        if (
          this.isImage(viewedItem) ||
          this.isVideo(viewedItem) ||
          this.isYoutubeLink(viewedItem)
        ) {
          // If supported by library, replace index so gallery library can load modal
          this.index = viewedItem.id;
        } else {
          this.viewItem(viewedItem);
        }
      }, 500);
    },
    viewItem(item) {
      const splittedName = item.fileName.split(".");
      if (
        !supportedExtensions.includes(
          splittedName[splittedName.length - 1].toLowerCase()
        )
      ) {
        // If extension not supported, download item
        document.getElementById("hiddenIframe").src = item.url;
        return;
      }
      this.viewedItem = item;
      this.docViewerKey = "docViewerKey-" + Math.random() * 100;
      setTimeout(() => {
        this.$modal.show("docViewerModal");
      }, 500);
    },
    onFilter(filter) {
      this.filteredItems = this.items.filter((el) => {
        return el.fileName.toLowerCase().includes(filter.toLowerCase());
      });
      this.onSort(this.sortBy);
      this.filterMedia(this.filteredItems);
    },
    onSort(sortType = "-") {
      this.sortBy = sortType;
      if (sortType == "-") {
        this.filteredItems.sort((a, b) => {
          var nameA = a.fileName.toLowerCase();
          var nameB = b.fileName.toLowerCase();
          if (a.fileType == "folder" && b.fileType == "folder") {
            if (nameA < nameB) return -1;
            else return 1;
          } else {
            if (a.fileType == "folder") return -1;
            else if (b.fileType == "folder") return 1;
            else {
              if (nameA < nameB) return -1;
              else return 1;
            }
          }
        });
      } else if (sortType == "A-Z") {
        this.filteredItems.sort((a, b) => {
          var nameA = a.fileName.toLowerCase();
          var nameB = b.fileName.toLowerCase();
          if (nameA < nameB) return -1;
          else return 1;
        });
      } else if (sortType == "Z-A") {
        this.filteredItems.sort((a, b) => {
          var nameA = a.fileName.toLowerCase();
          var nameB = b.fileName.toLowerCase();
          if (nameA > nameB) return -1;
          else return 1;
        });
      }
    },
    isFolder(item) {
      return isFolder(item);
    },
    isLink(item) {
      return isLink(item);
    },
    isYoutubeLink(item) {
      return isYoutubeLink(item);
    },
    isOffice(item) {
      return isOfficeFileCheck(item);
    },
    isVideo(item) {
      return isVideoFileCheck(item);
    },
    isImage(item) {
      return isImageFileCheck(item);
    },
    changeIndex(event) {
      this.index = event;
    },
  },
};
</script>
